<template>
  <svg id="logo" class="logo" width="236" height="40" viewBox="0 0 236 40">
    <g id="g98" transform="matrix(.37476 0 0 .37476 -23.142 -18.277)">
      <g id="g68">
        <path
          id="polygon60"
          class="cls-1"
          fill="#11a3eb"
          d="M149.08 48.77h-11.14l-25.73 34.26 6.79 9.05 18.95-25.23v29.3h11.13z"
        />
        <g id="g66">
          <path
            id="polygon62"
            class="cls-1"
            fill="#11a3eb"
            d="M91.81 110.18l-18.93 25.21v-29.33H61.75v47.38H72.9l25.71-34.22z"
          />
          <path
            id="polygon64"
            class="cls-1"
            fill="#11a3eb"
            d="M105.42 110.16l32.51 43.28h11.15v-47.38h-11.13v29.33l-21.92-29.18L98.2 82.47l-25.31-33.7H61.75v47.38h11.13v-29.3l25.74 34.27-.01.02 6.79 9.04z"
          />
        </g>
      </g>
      <g id="g96">
        <path
          id="path70"
          d="M239.4 129.32l-.07-33.87-16.79 28h-4.24l-16.79-27.56v33.43h-9v-51.1h7.81l20.3 33.87 19.88-33.87h7.81l.07 51.1z"
        />
        <path
          id="path72"
          d="M333.1 126.66a25 25 0 01-9.79-9.39 27.4 27.4 0 010-27 25.32 25.32 0 019.82-9.38 28.8 28.8 0 0114-3.39 29.19 29.19 0 0111.46 2.19 23.5 23.5 0 018.78 6.31l-6.13 5.77a17.89 17.89 0 00-13.65-6 19.16 19.16 0 00-9.42 2.3 16.51 16.51 0 00-6.5 6.39 19.61 19.61 0 000 18.54 16.57 16.57 0 006.5 6.39 19.16 19.16 0 009.42 2.3 17.79 17.79 0 0013.65-6.06l6.13 5.84a23.41 23.41 0 01-8.8 6.35 29.41 29.41 0 01-11.49 2.19 28.8 28.8 0 01-13.98-3.35z"
        />
        <path
          id="path74"
          d="M388.83 126.66a25.15 25.15 0 01-9.85-9.42 27.13 27.13 0 010-26.94 25.15 25.15 0 019.85-9.42 31 31 0 0128.18 0 25.26 25.26 0 019.85 9.38 27.26 27.26 0 010 27 25.22 25.22 0 01-9.85 9.39 31 31 0 01-28.18 0zm23.29-7.23a16.74 16.74 0 006.42-6.43 19.37 19.37 0 000-18.46 16.67 16.67 0 00-6.42-6.43 19.55 19.55 0 00-18.4 0 16.6 16.6 0 00-6.42 6.43 19.37 19.37 0 000 18.46 16.67 16.67 0 006.42 6.43 19.55 19.55 0 0018.4 0z"
        />
        <path
          id="path76"
          d="M476.76 80.41a16.8 16.8 0 017.55 6.28 19.17 19.17 0 010 19.38 16.73 16.73 0 01-7.55 6.31 28.3 28.3 0 01-11.57 2.19h-11.54v14.75h-9.49v-51.1h21a28.3 28.3 0 0111.6 2.19zm-2.59 23.5a9.14 9.14 0 003.21-7.51 9.16 9.16 0 00-3.21-7.52c-2.15-1.76-5.28-2.63-9.42-2.63h-11.1v20.29h11.1c4.14 0 7.25-.87 9.42-2.63z"
        />
        <path
          id="path78"
          d="M538.41 121.36v8h-38.33V78.22h37.31v8h-27.82v13.24h24.68v7.81h-24.68v14.09z"
        />
        <path
          id="path80"
          d="M552.64 78.22H575a32.18 32.18 0 0114.53 3.17 23.49 23.49 0 019.85 9 27.31 27.31 0 010 26.79 23.43 23.43 0 01-9.85 9 32 32 0 01-14.53 3.14h-22.36zm21.9 43.07a21.72 21.72 0 009.89-2.15A15.37 15.37 0 00591 113a19.67 19.67 0 000-18.46 15.37 15.37 0 00-6.57-6.14 21.72 21.72 0 00-9.89-2.15h-12.41v35z"
        />
        <path id="path82" d="M616.59 78.22h9.49v51.1h-9.49z" />
        <g id="g88">
          <path
            id="polygon84"
            d="M276.85 88.37l-18.41 40.91h9.78l12.96-30.27z"
          />
          <path id="polygon86" d="M290.78 78.18h-8.81l21.95 51.1h9.93z" />
        </g>
        <g id="g94">
          <path
            id="polygon90"
            d="M654.48 88.37l-18.4 40.91h9.78l12.95-30.27z"
          />
          <path id="polygon92" d="M668.42 78.18h-8.81l21.95 51.1h9.92z" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style lang="scss">
.cls-1 {
  fill: color(cerulean);
}

.logo {
  display: block;
  width: 180px;
  animation: logoAnimation 5s forwards;

  @include media-query(lg) {
    width: 236px;
  }
}

@keyframes logoAnimation {
  0% {
    stroke: currentColor;
    stroke-width: 1;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    fill: transparent;
  }

  25% {
    fill: transparent;
    stroke: currentColor;
  }

  40% {
    fill: currentColor;
  }

  50% {
    stroke: transparent;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
</style>
